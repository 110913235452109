.row{
	border: 1px solid blue;
	position: fixed;
	width:100%;
}

.dashboard{
	border: 1px dotted grey;
	width:320px;
	height:40px;
	margin:auto;
	background: lightgrey;
}

.battle{
	border: 1px dotted grey;
	position: relative;
	height: 220px;
	width:320px;
  margin:auto;
  background: url(Background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.cat{
	background-repeat: no-repeat;
	position: absolute;
-webkit-transition: right 1s; 
  transition: right 1s;
}
.enemy{
	background-repeat: no-repeat;
	position: absolute;
-webkit-transition: left 1s; 
  transition: left 1s;
}

.CatBase {
	text-align: center;
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
}
  
.EnemyBase {
	text-align: center;
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
  }

  .App {
	text-align: center;
  }

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
